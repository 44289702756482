
import { mapGetters } from 'vuex'
export default {
    name: 'AnnouncementText',
    computed: {
        ...mapGetters({
            announcement: 'cms/announcement',
        }),
        announcements() {
            let anouncement = ''
            const anouns = this.announcement
            let idx = 0
            for (const a of this.getVisibleItems(anouns?.items)) {
                idx += 1
                anouncement += `${idx}) ${this.getLocaleLang(a.title)} `
            }
            return anouncement
        },
    },
    mounted() {
        // Call this store action only when the user is not on the home page and mobile device
        if (this.$route.path !== '/' && !this.isMobileOnly()) {
            this.$store.dispatch('cms/cmsComponentCode', {
                platform: 1,
                component_code: 'Announcement',
            })
        }
    },
}
