import { render, staticRenderFns } from "./CustomFooter.vue?vue&type=template&id=17fa452c&scoped=true"
import script from "./CustomFooter.vue?vue&type=script&lang=js"
export * from "./CustomFooter.vue?vue&type=script&lang=js"
import style0 from "./CustomFooter.vue?vue&type=style&index=0&id=17fa452c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17fa452c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsNuxtImage: require('/app/components/Commons/NuxtImage.vue').default})
