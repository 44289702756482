
import { mapState, mapGetters } from 'vuex'
import cms from '~/mixins/common/cms'
export default {
    name: 'Theme3NavBar',
    mixins: [cms],
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
            player: (state) => state.player.player,
        }),
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
        }),
        isPlayGamePage() {
            if (this.$route.path === '/play-game') return true
            return false
        },
    },
    mounted() {
        // Call this store action only when the user is not on the home page
        if (this.$route.path !== '/')
            this.$store.dispatch('cms/cmsComponentCode', {
                platform: 1,
                component_code: 'HeaderLogo',
            })
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
}
